import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';
// Initialize languages
import 'locales/i18n';
import 'utils/axios.interceptors';

import { CssBaseline } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
// eslint-disable-next-line no-restricted-imports
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { ThemeProvider } from 'impact-react-components';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from 'app';
import { AppErrorBoundary } from 'app/containers/AppErrorBoundary';
import { persistor, store } from 'store';

const MOUNT_NODE: HTMLElement = document.getElementById('root') as HTMLElement;
const root = createRoot(MOUNT_NODE);

interface Props {
  Component: typeof App;
}
export const ConnectedApp = ({ Component }: Props) => (
  <AppErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <>
            <CssBaseline />
            <HelmetProvider>
              <LocalizationProvider dateAdapter={MomentUtils}>
                <Component />
              </LocalizationProvider>
            </HelmetProvider>
          </>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </AppErrorBoundary>
);

const render = (Component: typeof App) => {
  root.render(<ConnectedApp Component={Component} />);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app', './locales/i18n'], () => {
    root.unmount();
    const App = require('./app').App;
    render(App);
  });
}

render(App);
