import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';

import { EDatabaseName } from 'helpers/constants';
import { UserInfo } from 'types/UserInfo';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { UserInfoState } from './types';

export const initialState: UserInfoState = {
  user: null,
  status: RequestStatus.IDLE,
  error: null,
  userVisitedDatesStatus: RequestStatus.IDLE,
  userVisitedDatesError: null,
  selectedDatabase: null,
  isUserFromIframe: null,
};

const userInfoSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUserInfo(state) {
      state.status = RequestStatus.LOADING;
      state.error = null;
      state.user = null;
    },
    userInfoLoaded(state, action: PayloadAction<UserInfo>) {
      state.status = RequestStatus.SUCCESS;
      state.error = null;
      state.user = action.payload;
    },
    userInfoError(state, action: PayloadAction<unknown>) {
      state.status = RequestStatus.FAILURE;
      state.error = action.payload;
      state.user = null;
    },
    clearUserInfo(state) {
      state.status = RequestStatus.IDLE;
      state.error = null;
      state.user = null;
      state.userVisitedDatesStatus = RequestStatus.IDLE;
    },
    updateUserVisitedDates(state) {
      state.userVisitedDatesStatus = RequestStatus.LOADING;
      state.userVisitedDatesError = null;
    },
    userVisitedDatesUpdated(state) {
      state.userVisitedDatesStatus = RequestStatus.SUCCESS;
    },
    userVisitedDatesError(state, action: PayloadAction<string>) {
      state.userVisitedDatesStatus = RequestStatus.FAILURE;
      state.userVisitedDatesError = action.payload;
    },
    setSelectedDatabase(state, action: PayloadAction<EDatabaseName>) {
      state.selectedDatabase = action.payload;
    },
    setIsUserFromIframe(state, action: PayloadAction<boolean>) {
      state.isUserFromIframe = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = userInfoSlice;
