import { colors } from 'styles/theme/colors';
import { CurveType } from 'types/Curve';

export const CURVE_COLOR = {
  [CurveType.LIQUID]: colors.baseGreen55,
  [CurveType.GAS]: colors.baseRed55,
  [CurveType.WATER]: colors.baseBlue55,
} as const;

export const UPSTREAM_ENHANCED_EMISSIONS = 'Upstream Enhanced Emissions';
export const NEW = 'new';

export const DEFAULT_LEGACY_DATABASE_DASHBOARDS = ['Upstream Enhanced Emissions'];

export const DEFAULT_CSM_DASHBOARDS = [
  'Exploration & Production',
  'Carbon Capture, Utilization & Storage',
  'Upstream Enhanced Emissions',
  'Land Analytics',
  'Oilfield Services',
];
export const DEFAULT_INFORMATION_SCHEMA_DASHBOARDS: string[] = [];
