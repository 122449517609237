import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';
import { PredictGeoDataPayload } from 'api/map';

import { MapWidgetId } from 'types/Widget';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { MapWidgetPredictLayerState, MapWidgetsPredictLayersState, PredictionGeoPoint } from './types';

const initialMapWidgetLayerState: MapWidgetPredictLayerState = {
  status: RequestStatus.IDLE,
  error: null,
  data: null,
  rendered: true,
};

export const initialState: MapWidgetsPredictLayersState = {
  [MapWidgetId.PREDICT_MAP_WIDGET]: initialMapWidgetLayerState,
  [MapWidgetId.PLAN_MAP_WIDGET]: initialMapWidgetLayerState,
};

const mapWidgetsSlice = createSlice({
  name: 'mapWidgetsPredictLayers',
  initialState,
  reducers: {
    loadGeoData(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; payload: PredictGeoDataPayload }>) {
      state[payload.mapWidgetId].status = RequestStatus.LOADING;
      state[payload.mapWidgetId].error = null;
      state[payload.mapWidgetId].rendered = false;
    },
    geoDataLoaded(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; data: PredictionGeoPoint[] }>) {
      state[payload.mapWidgetId].status = RequestStatus.SUCCESS;
      state[payload.mapWidgetId].data = payload.data;
    },
    geoDataError(state, { payload }: PayloadAction<{ mapWidgetId: MapWidgetId; error: string }>) {
      state[payload.mapWidgetId].status = RequestStatus.FAILURE;
      state[payload.mapWidgetId].error = payload.error;
      state[payload.mapWidgetId].data = null;
    },
    clearGeoDataError(state, { payload: mapWidgetId }: PayloadAction<MapWidgetId>) {
      state[mapWidgetId].error = null;
      state[mapWidgetId].status = RequestStatus.IDLE;
    },
    geoDataRemove(state, { payload: mapWidgetId }: PayloadAction<MapWidgetId>) {
      state[mapWidgetId].status = RequestStatus.IDLE;
      state[mapWidgetId].error = null;
      state[mapWidgetId].data = null;
    },
    setLayerRendered(
      state,
      { payload: { mapWidgetId, rendered } }: PayloadAction<{ mapWidgetId: MapWidgetId; rendered: boolean }>,
    ) {
      state[mapWidgetId].rendered = rendered;
    },
  },
});

export const { actions, reducer, name: sliceKey } = mapWidgetsSlice;
