import { EDatabaseName } from 'helpers/constants';

export const setSelectedDatabaseByOrigin = (selectedDB: EDatabaseName, availableDB: EDatabaseName[]) => {
  const isUserFromIframBasedOnWindowObject = window.self !== window.top;

  let result = null;

  if (selectedDB) {
    result = selectedDB;
  } else if (availableDB.length > 1 && isUserFromIframBasedOnWindowObject && availableDB.includes(EDatabaseName.CSM)) {
    result = EDatabaseName.CSM;
  } else if (
    availableDB.length > 1 &&
    !isUserFromIframBasedOnWindowObject &&
    availableDB.includes(EDatabaseName.HEAVYAI)
  ) {
    result = EDatabaseName.HEAVYAI;
  } else if (availableDB.includes(EDatabaseName.HEAVYAI)) {
    result = EDatabaseName.HEAVYAI;
  } else {
    result = EDatabaseName.CSM;
  }

  return result;
};
