import { api } from 'constants/API';

import axios, { CancelTokenSource } from 'axios';

import { EDatabaseName } from 'helpers/constants';

import { UserInfo } from '../types/UserInfo';

export const fetchUserInfo = () => axios.get<UserInfo>(api.getUserInfo);

export const changeDefaultDatabaseInHeavy = (selectedDatabase: EDatabaseName, cancelToken: CancelTokenSource) =>
  axios.post(api.changeDefaultDatabaseInHeavy, { selectedDatabase }, { cancelToken: cancelToken.token });

export const updateUserStatus = () => axios.patch(api.updateUserVisitedDates);
