export enum ProductUpdateType {
  Notification = 'x Notification',
  DataUpdate = 'xData Update',
  ProductUpdate = 'xProduct Update',
}

export interface ProductUpdate {
  messageId: string;
  htmlbody: string;
  releasedTime: string;
  isRead: boolean;
  productupdatetype: ProductUpdateType;
}
