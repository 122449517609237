import { AppRoute } from 'constants/AppRoute';
import { TestLocators } from 'constants/Locators';

import { Typography } from '@material-ui/core';
import { useIsDarkMode } from 'hooks/useIsDarkMode';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { log } from 'services/log';
import { LogEvent } from 'services/LogEvent';
import { LogSource } from 'services/LogSource';

import { ReactComponent as darkLogoIcon } from './logo-dark.svg';
import { ReactComponent as lightLogoIcon } from './logo-light.svg';
import { useStyles } from './styles';

export function Logo() {
  const classes = useStyles();
  const { t } = useTranslation();
  const isDarkMode = useIsDarkMode();
  const LogoIcon = isDarkMode ? darkLogoIcon : lightLogoIcon;
  const history = useHistory();

  const handleClick = () => {
    log.info({
      event: LogEvent.CLICK,
      source: LogSource.LANDING_PAGE,
    });
    history.push(AppRoute.ROOT);
  };

  return (
    <div className={classes.wrapper}>
      <LogoIcon onClick={handleClick} />
      <Typography onClick={handleClick} className={classes.productName} data-testid={TestLocators.GoTo_LandingPage}>
        {t('COMMON.TITLE')}
      </Typography>
    </div>
  );
}
