import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    wrapper: {
      height: 64,
      marginRight: 24,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('md')]: {
        marginRight: 5,
      },
    },
    productName: {
      height: 40,
      font: 'bold 20px "Akkurat", sans-serif',
      color: isDarkTheme ? colors.textInverseDefault : colors.textPrimaryDefault,
      borderLeft: `1px solid ${colors.baseGray11}`,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
    },
  });
});
