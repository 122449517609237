import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  const { fontWeightRegular } = theme.typography;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    animatedBorder: {
      background: colors.grey45,
      width: '100%',
      height: 1,
      position: 'absolute',
      left: 0,
    },
    markAll: {
      cursor: 'pointer',
      fontSize: 12,
      color: isDarkTheme ? colors.primaryWhite : colors.primaryBlue,
      textDecoration: 'none',
      fontWeight: 400,
    },
    menuItem: {
      width: 365,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '14px 20px',
    },
    label: {
      color: isDarkTheme ? colors.baseGrey10 : colors.grey42,
    },
    btnNews: {
      color: isDarkTheme ? colors.baseGrey10 : colors.grey40,
    },
    highLight: {
      width: '8px',
      height: '8px',
      background: colors.stateWarningDefault,
      borderRadius: '20px',

      flex: 'none',
      order: 0,
      flexGrow: 0,
    },
    selectTheme: {
      fontWeight: 400,
      color: isDarkTheme ? colors.grey12 : colors.grey54,
      cursor: 'default',
    },
    menuItemPadding: {
      paddingBottom: '0px',
      paddingTop: '4px',
    },
    textFieldBorder: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputLabel-outlined': {
        fontSize: '16px',
        marginLeft: '7px',
        color: 'gray',
        fontWeight: 400,
      },
    },
    rootMenuItem: {
      '&:hover, &.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: isDarkTheme ? colors.grey54 : colors.grey12,
        color: isDarkTheme ? colors.grey12 : colors.grey54,
      },
      fontSize: '16px',
      backgroundColor: isDarkTheme ? colors.grey56 : colors.baseGrey05,
      color: isDarkTheme ? colors.grey45 : colors.baseGrey35,
      fontFamily: '"Akkurat", sans-serif',
      fontWeight: 400,
      padding: '10px',
      cursor: 'default',
    },
    menuItemBorder: {
      border: '1px solid',
      overflowY: 'scroll',
      borderColor: isDarkTheme ? colors.grey56 : colors.grey15,
    },
    value: {
      fontWeight: fontWeightRegular as number,
      color: isDarkTheme ? colors.baseGrey10 : colors.grey51,
      paddingLeft: 6,
      overflowWrap: 'break-word',
    },
    databaseLabel: {
      fontSize: '14px',
    },
    button: {
      boxShadow: 'none',
      borderRadius: 4,
      height: 32,
    },
    divider: {
      width: '100%',
      margin: theme.spacing(2, 0),
    },
    container: {
      position: 'relative',
      width: '365px',
      padding: 20,
      color: colors.primaryWhite,
      marginBottom: 'auto',
      overflowY: 'auto',
    },
    loader: {
      minHeight: '500px',
    },
    notification: {
      fontSize: 12,
      lineHeight: '16px',
      color: isDarkTheme ? colors.primaryWhite : colors.grey56,

      '&:not(:last-child)': {
        marginBottom: 26,
      },
    },
    date: {
      marginBottom: 6,
    },
    descriptionBoldText: {
      fontWeight: 'bold',
    },
    dotIndicator: {
      display: 'inline-block',
      width: 8,
      height: 8,
      backgroundColor: colors.stateWarningDefault,
      borderRadius: '50%',
    },
    unreadNotificationDot: {
      marginRight: 11,
    },
    description: {
      '& img': {
        display: 'block',
        margin: '0 auto',
        height: 'auto',
        width: 'auto',
        maxWidth: '100%',
        maxHeight: '500px',
      },

      '& [data-updates-value]': {
        display: 'inline',
        cursor: 'pointer',
        textDecoration: 'underline',
      },

      '& a': {
        color: isDarkTheme ? colors.primary : colors.primaryBlue,
      },
    },
    viewAllContainer: {
      textAlign: 'center',
      padding: '10px 0',
    },
    viewAllLink: {
      outline: 'none',
      background: 'none',
      border: 0,
      cursor: 'pointer',
      color: isDarkTheme ? colors.primaryWhite : colors.primaryBlue,
      textDecoration: 'none',
      fontWeight: 400,
    },
    notificationsPaper: {
      backgroundColor: isDarkTheme ? colors.grey40 : colors.primaryWhite,
      top: '66px',
      right: '7px',
      height: 'calc(100% - 81px)',
      borderRadius: '0 0 2px 2px',
    },
    notificationsBackdrop: {
      backgroundColor: 'transparent',
    },
    notificationIcon: {
      position: 'relative',
      cursor: 'pointer',
    },
    notificationIconDot: {
      position: 'absolute',
      right: 4,
      top: 2,
    },
    notificationGroupsContainer: {
      '& .MuiButtonGroup-root': {
        width: '100%',
      },
    },
    notificationGroups: {},
    groupButton: {
      color: isDarkTheme ? 'inherit' : colors.primaryBlue,
      width: '100%',
      borderColor: isDarkTheme ? colors.grey45 : `rgba(0, 137, 209, 0.23)`,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  });
});
