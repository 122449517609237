import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    link: {
      display: 'inline-block',
      width: 32,
      height: 32,
      padding: 2,
      marginLeft: 10,
    },
  }),
);
