export enum TableName {
  IMPACT_WELL_AND_PROD = 'Impact_Well_and_Prod',
  ACREAGE_AND_INVENTORY = 'Acreage_and_Inventory',
  MIDSTREAM_PIPELINES = 'Midstream_Pipelines',
  WELL_PATH = 'Well_Path',
  SUBSURFACE_ISOPACH = 'Subsurface_Isopach',
  SUBSURFACE_THERMAL_MATURITY = 'Subsurface_Thermal_Maturity',
  SUBSURFACE_STRUCTURE = 'Subsurface_Structure',
  SUBSURFACE_FAULTS = 'Subsurface_Faults',
  COMPANY_ACREAGE = 'Company_Acreage',
}
