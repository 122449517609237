import { TestLocators } from 'constants/Locators';

import { Box, Button, Dialog, DialogContent, Tab, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TabContext, TabList } from '@material-ui/lab';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ProductUpdateType } from 'types/ProductUpdate';

import { NotificationsList } from '../NotificationsList';
import { selectActiveProductUpdateInWindow, selectScrollProductUpdateState } from '../selectors';
import { actions as notificationsActions } from '../slice';
import { ParsedProductUpdate } from '../types';
import { useStyles } from './styles';
import { TabPanel } from './TabPanel';

export interface NotificationsWindowProps {
  isOpen: boolean;
  notifications: ParsedProductUpdate[];
  hasNewNotifications: boolean;
  onClose(): void;
  handleAllAsRead?: () => void;
  handleSelectNotificationsCategory: (category: ProductUpdateType) => void;
}

export const NotificationsWindow = ({
  isOpen,
  notifications,
  hasNewNotifications,
  onClose,
  handleAllAsRead,
  handleSelectNotificationsCategory,
}: NotificationsWindowProps) => {
  const classes = useStyles();
  const activeProductUpdateInWindow = useSelector(selectActiveProductUpdateInWindow);
  const scrollProductUpdateState = useSelector(selectScrollProductUpdateState);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeCategory, setActiveCategory] = useState(ProductUpdateType.Notification);
  const productUpdatesContainerRef = useRef<HTMLDivElement | null>(null);
  const notificationsContainerRef = useRef<HTMLDivElement | null>(null);
  const dataUpdatesContainerRef = useRef<HTMLDivElement | null>(null);

  const resetTabsPosition = useCallback(() => {
    productUpdatesContainerRef.current?.scrollTo(0, 0);
    notificationsContainerRef.current?.scrollTo(0, 0);
    dataUpdatesContainerRef.current?.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setActiveCategory(activeProductUpdateInWindow);
  }, [activeProductUpdateInWindow]);

  const getTabId = (type: ProductUpdateType) => type.replace(' ', '_');

  const handleImagesLoaded = useCallback(async () => {
    resetTabsPosition();
    const productUpdateTab = document.getElementById(getTabId(activeCategory));

    if (!scrollProductUpdateState?.date) return;
    const productUpdate = productUpdateTab?.getElementsByClassName(scrollProductUpdateState?.date)[0];

    if (productUpdateTab && productUpdate) {
      await Promise.all(
        Array.from(productUpdateTab?.querySelectorAll('img'))
          .filter((img: HTMLImageElement) => !img.complete)
          .map(
            (img) =>
              new Promise((resolve) => {
                img.addEventListener('load', resolve);
                img.addEventListener('error', resolve);
              }),
          ),
      );

      productUpdate.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
    }
  }, [scrollProductUpdateState?.date, activeCategory, resetTabsPosition]);

  useEffect(() => {
    handleImagesLoaded();
  }, [handleImagesLoaded]);

  const getTabIndex = useCallback(
    (type: ProductUpdateType) => Object.values(ProductUpdateType).findIndex((value) => value === type),
    [],
  );

  const getNotifications = useCallback(
    (category: ProductUpdateType) => {
      const filteredNotifications = notifications.filter(({ productupdatetype }) => productupdatetype === category);

      return (
        <Box data-testid={TestLocators.NotificationsPopUp_Content} sx={{ paddingBottom: 24 }}>
          <NotificationsList
            notifications={filteredNotifications}
            handleSelectNotificationsCategory={handleSelectNotificationsCategory}
          />
        </Box>
      );
    },
    [notifications, handleSelectNotificationsCategory],
  );

  const handleChange = useCallback(
    (_event: ChangeEvent<{}>, index: number) => {
      resetTabsPosition();
      dispatch(notificationsActions.setScrollProductUpdateState(null));

      const type = Object.values(ProductUpdateType)[index];
      dispatch(notificationsActions.setActiveProductUpdateInWindow(type));
    },
    [dispatch, resetTabsPosition],
  );

  const renderTabs = useMemo(() => {
    const notificationsIndex = getTabIndex(ProductUpdateType.Notification).toString();
    const dataUpdateIndex = getTabIndex(ProductUpdateType.DataUpdate).toString();
    const productUpdateIndex = getTabIndex(ProductUpdateType.ProductUpdate).toString();

    return (
      <div className={classes.tabsContainer}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TabList
            className={classes.tabsList}
            variant="standard"
            value={getTabIndex(activeCategory)}
            onChange={handleChange}
          >
            <Tab
              data-testid={TestLocators.NotificationsPopUp_AllNotifications}
              label={t('LANDING_PAGE.NOTIFICATIONS.VIEW_ALL')}
              value={notificationsIndex}
            />
            <Tab
              data-testid={TestLocators.NotificationsPopUp_DataUpdates}
              label={t('LANDING_PAGE.DATA_UPDATES')}
              value={dataUpdateIndex}
            />
            <Tab
              data-testid={TestLocators.NotificationsPopUp_PlatformUpdates}
              label={t('LANDING_PAGE.PLATFORM_UPDATES')}
              value={productUpdateIndex}
            />
          </TabList>
          {hasNewNotifications && (
            <Typography
              data-testid={TestLocators.NotificationsPopUp_MarkAllRead}
              variant="h5"
              onClick={handleAllAsRead}
              className={classes.markAll}
            >
              {t('LANDING_PAGE.NOTIFICATIONS.MARK_ALL')}
            </Typography>
          )}
          <Button data-testid={TestLocators.NotificationsPopUp_Close} className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
        <TabPanel value={productUpdateIndex}>
          <div
            id={getTabId(ProductUpdateType.ProductUpdate)}
            ref={productUpdatesContainerRef}
            className={classes.notificationsList}
          >
            {getNotifications(ProductUpdateType.ProductUpdate)}
          </div>
        </TabPanel>
        <TabPanel value={notificationsIndex}>
          <div
            id={getTabId(ProductUpdateType.Notification)}
            ref={notificationsContainerRef}
            className={classes.notificationsList}
          >
            {getNotifications(ProductUpdateType.Notification)}
          </div>
        </TabPanel>
        <TabPanel value={dataUpdateIndex}>
          <div
            id={getTabId(ProductUpdateType.DataUpdate)}
            ref={dataUpdatesContainerRef}
            className={classes.notificationsList}
          >
            {getNotifications(ProductUpdateType.DataUpdate)}
          </div>
        </TabPanel>
      </div>
    );
  }, [
    getTabIndex,
    classes,
    activeCategory,
    handleChange,
    t,
    hasNewNotifications,
    handleAllAsRead,
    onClose,
    getNotifications,
  ]);

  return (
    <Dialog
      data-testid={TestLocators.NotificationsPopUp}
      closeAfterTransition
      classes={{ paper: classes.dialogPaper }}
      onClose={onClose}
      open={isOpen}
    >
      <DialogContent className={classes.dialogContent}>
        <TabContext value={getTabIndex(activeCategory).toString()}>{renderTabs}</TabContext>
      </DialogContent>
    </Dialog>
  );
};
