import { api } from 'constants/API';
import { TestLocators } from 'constants/Locators';

import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  PopoverProps,
  Select,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { useIsDarkMode } from 'hooks/useIsDarkMode';
import { DarkThemeContext, ThemeMode } from 'impact-react-components';
import { ChangeEvent, MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

import { actions as oktaActions } from 'app/components/pages/OktaLoginPage/slice';
import { selectUsername, userAvailableDatabases, userSelectedDatabase } from 'app/containers/PrivateRoute/selectors';
import { actions } from 'app/containers/PrivateRoute/slice';
import { EDatabaseName } from 'helpers/constants';
import { domainName } from 'helpers/domain';
import { translations } from 'locales/i18n';
import { SAM_JWT_ACCESS_TOKEN, SELECTED_DATABASE } from 'utils/cookie';

import { useStyles } from './styles';

export interface ProfileMenuBodyProps {
  onClose: PopoverProps['onClose'];
}
interface LogoutRequestBody {
  logoutPageUrl?: string;
  source: string;
}

export function ProfileMenuBody({ onClose }: ProfileMenuBodyProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isDarkMode = useIsDarkMode();
  const username = useSelector(selectUsername);
  const availableDatabasesForUser = useSelector(userAvailableDatabases);
  const selectedDatabaseFromRedux = useSelector(userSelectedDatabase);
  const { setDarkMode } = useContext(DarkThemeContext);
  const cookies = useMemo(() => new Cookies(), []);
  const [selectedDatabase, setSelectedDatabase] = useState(selectedDatabaseFromRedux);

  const handleThemeChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      if (onClose) {
        onClose(event, 'backdropClick');
      }
      setDarkMode(event.target.value === ThemeMode.DARK);
    },
    [setDarkMode, onClose],
  );

  const handleDatabaseChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      if (onClose) {
        onClose(event, 'backdropClick');
      }
      cookies.set(SELECTED_DATABASE, event.target.value, { path: '/', domain: domainName() });
      dispatch(actions.setSelectedDatabase(event.target.value as EDatabaseName));
      setSelectedDatabase(event.target.value as EDatabaseName);
    },
    [cookies, dispatch, onClose],
  );

  useEffect(() => {
    cookies.set(SELECTED_DATABASE, selectedDatabase, { path: '/', domain: domainName() });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (onClose) {
        onClose(event, 'backdropClick');
      }
      const logoutRequestData: LogoutRequestBody = {
        logoutPageUrl: window.location.toString(),
        source: 'Logout Button',
      };

      dispatch(oktaActions.clearUserEmailFromOkta());
      document.cookie = `${SAM_JWT_ACCESS_TOKEN}= ; Secure; Domain=${domainName()}; path=/;`;
      axios.delete(api.logout, { data: logoutRequestData }).finally(() => {
        window.location.href = `${process.env.REACT_APP_IHS_AUTH_URL}/clearsession`;
      });
    },
    [dispatch, onClose],
  );

  return (
    <MenuItem
      style={{ backgroundColor: 'transparent', color: 'white' }}
      className={classes.menuItem}
      disableRipple
      data-testid={TestLocators.UserProfile_Menu}
    >
      <div>
        <Typography
          variant="body1"
          component="span"
          className={`${classes.label} ${classes.profileLabel}`}
          data-testid={`${TestLocators.UserProfile_Menu}_Logged_Label`}
        >
          {t(translations.PROFILE.LOGGED_LABEL)}
        </Typography>
        <Typography
          variant="body1"
          component="span"
          noWrap={false}
          style={{ display: 'content', whiteSpace: 'pre-line', width: '100%' }}
          className={`${classes.value} ${classes.profileLabel}`}
          title={username}
          data-testid={`${TestLocators.UserProfile_Menu}_Username`}
        >
          {username}
        </Typography>
      </div>

      {/*<div className={classes.dataItem}>
          <Typography variant="body1" component="span" className={`${classes.label} ${classes.databaseLabel}`}>
            {t(translations.PROFILE.DATABASE_LABEL)}
            {':'}
          </Typography>
          <Typography
            variant="body1"
            component="span"
            className={`${classes.value} ${classes.databaseLabel}`}
            data-testid={`${TestLocators.UserProfile_Menu}_Database`}
            gutterBottom
          >
            {selectedDatabase}
          </Typography>
        </div> */}

      {availableDatabasesForUser.length === 1 ? (
        <div className={classes.dataItem}>
          <Typography variant="body1" component="span" className={`${classes.label} ${classes.databaseLabel}`}>
            {t(translations.PROFILE.DATABASE_LABEL)}
            {':'}
          </Typography>
          <Typography
            variant="body1"
            component="span"
            className={`${classes.value} ${classes.databaseLabel}`}
            data-testid={`${TestLocators.UserProfile_Menu}_Database`}
            gutterBottom
          >
            {selectedDatabase}
          </Typography>
        </div>
      ) : (
        <div style={{ width: '100%', margin: '16px 0' }}>
          <FormControl variant="outlined" fullWidth classes={{ root: classes.textFieldBorder }}>
            <InputLabel id="change-theme-label">{t(translations.PROFILE.DATABASE_LABEL)}</InputLabel>
            <Select
              labelId="change-theme-label"
              id="change-theme"
              value={selectedDatabase}
              onChange={handleDatabaseChange}
              label={t(translations.PROFILE.DATABASE_LABEL)}
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: { paper: classes.menuItemBorder },
                MenuListProps: {
                  classes: { padding: classes.menuItemPadding },
                },
              }}
              classes={{ root: classes.selectStyle }}
            >
              {availableDatabasesForUser.map((databaseName: string) => {
                return (
                  <MenuItem value={databaseName} classes={{ root: classes.rootMenuItem }}>
                    {databaseName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      )}
      <div style={{ width: '100%', marginTop: '4px' }}>
        <FormControl variant="outlined" fullWidth classes={{ root: classes.textFieldBorder }}>
          <InputLabel id="change-theme-label">{t(translations.PROFILE.THEME_LABEL)}</InputLabel>
          <Select
            labelId="change-theme-label"
            id="change-theme"
            value={isDarkMode ? ThemeMode.DARK : ThemeMode.LIGHT}
            onChange={handleThemeChange}
            label={t(translations.PROFILE.THEME_LABEL)}
            variant="outlined"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              classes: { paper: classes.menuItemBorder },
              MenuListProps: {
                classes: { padding: classes.menuItemPadding },
              },
            }}
            classes={{ root: classes.selectStyle }}
          >
            <MenuItem value={ThemeMode.DARK} classes={{ root: classes.rootMenuItem }}>
              {t(translations.PROFILE.DARK_MODE)}
            </MenuItem>
            <MenuItem value={ThemeMode.LIGHT} classes={{ root: classes.rootMenuItem }}>
              {t(translations.PROFILE.LIGHT_MODE)}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.dataItem}>
        <Button
          className={classes.button}
          fullWidth
          variant="outlined"
          onClick={handleLogout}
          data-testid={`${TestLocators.UserProfile_Menu}_Logout_Btn`}
        >
          {t(translations.PROFILE.LOGOUT)}
        </Button>
      </div>
    </MenuItem>
  );
}
