import { api } from 'constants/API';

import axios from 'axios';

import { UserInfo } from 'types/UserInfo';

const dataLayer = (window as any).dataLayer || [];

export const trackUserInfo = async () => {
  try {
    const response = await axios.get<UserInfo>(api.getUserInfo);
    const userInfo = response.data;
    const internalDomains = ['impact', 'spglobal', 'ihsmarkit', 'ihs', 'epam', 'test.ciam'].map(
      (name) => `${name}.com`,
    );
    const userDomain = userInfo.userDetails.email.split('@')[1].toLowerCase();
    const isInternal = internalDomains.includes(userDomain);
    dataLayer.push({
      event: 'load',
      user: [
        {
          id: userInfo.userId, // NIMS User ID
          industry: null, // no industry data in NIMS
          organization: userInfo.userDetails.company,
          package: userInfo.roles,
          internal: isInternal,
        },
      ],
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('[WARN] fetching user info:', error);
  }
};

export const trackVirtualPageview = () => {
  const vPagePath = window.location.href;
  const vPageTitle = document.title;
  dataLayer.push({
    event: 'VirtualPageview',
    VPagePath: vPagePath,
    VPageTitle: vPageTitle,
  });
};

const handleClick = (linkPath: string, linkText: string, eventName: string) => {
  const url = new URL(window.location.href);
  const linkUrl = `${url.protocol}//${url.host}${linkPath}`;
  dataLayer.push({
    event: eventName,
    link_text: linkText,
    link_url: linkUrl,
  });
};

export const headerNavClick = (linkPath: string) => {
  const linkText: string | undefined = linkPath.split('%2F').pop()?.toUpperCase().replace(/-/g, ' ');
  handleClick(linkPath, linkText || '', generateEventName(headerNavClick.name));
};

export const landingPageClick = (linkPath: string, linkText: string) => {
  handleClick(linkPath, linkText, generateEventName(landingPageClick.name));
};

const generateEventName = (functionName: string) => {
  return functionName.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};
