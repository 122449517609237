import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_LAYER_SETTINGS } from 'app/components/containers/map/MapLayersSelect/config';
import { getPreDefinedColorListByLayerAndField } from 'app/components/containers/widgets/MapWidget/VegaLayer/vega/utils';
import {
  changeColorAlpha,
  generateRGBAColorPalette,
  generateRGBAColors,
  generateRangeColorPalette,
} from 'helpers/colors';
import { PostgreDataType } from 'types/PostgreDataType';
import { MapWidgetId } from 'types/Widget';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { layersColoredByColorPalette } from './constants';
import { GetColorByDomain } from './saga';
import { selectMapLayerOpacity } from './selectors';
import { Column, MapLayer, MapLayerSettings, MapLayerSettingsState, MapWidgetsLayerSettingsState } from './types';

const initialMapLayerSettingsState: MapLayerSettingsState = {
  layers: [],
  domainStatus: RequestStatus.IDLE,
  domainError: null,
  removedLayer: null,
};

export const initialState: MapWidgetsLayerSettingsState = {
  [MapWidgetId.PREDICT_MAP_WIDGET]: {
    ...initialMapLayerSettingsState,
    layers: [
      {
        id: MapLayer.WELLS,
        ...DEFAULT_LAYER_SETTINGS[MapLayer.WELLS],
      },
    ],
  },
  [MapWidgetId.PLAN_MAP_WIDGET]: {
    ...initialMapLayerSettingsState,
    layers: [
      {
        id: MapLayer.WELL_PATH,
        ...DEFAULT_LAYER_SETTINGS[MapLayer.WELL_PATH],
      },
      {
        id: MapLayer.WELLS,
        ...DEFAULT_LAYER_SETTINGS[MapLayer.WELLS],
      },
      {
        id: MapLayer.PREDICTION,
        ...DEFAULT_LAYER_SETTINGS[MapLayer.PREDICTION],
      },
    ],
  },
};

const changeSettingsValue = <T>(
  layers: MapLayerSettings[],
  layerId: MapLayer,
  settingsName: Exclude<keyof MapLayerSettings, 'id'>,
  newValue: T,
) => {
  const currentLayerIndex = layers.findIndex((layer) => layer.id === layerId);
  if (currentLayerIndex !== -1) {
    const currentLayer = layers[currentLayerIndex];

    if (settingsName === 'opacity' && currentLayer.colorByValue !== null && typeof newValue === 'number') {
      layers[currentLayerIndex] = {
        ...currentLayer,
        [settingsName]: newValue,
        colorByValue: {
          ...currentLayer.colorByValue,
          range: currentLayer.colorByValue.range
            ? changeColorAlpha(currentLayer.colorByValue.range, newValue / 100)
            : currentLayer.colorByValue.range,
        },
      };
    } else {
      layers[currentLayerIndex] = {
        ...currentLayer,
        [settingsName]: newValue,
      };
    }
  }

  return layers;
};

const mapWidgetsLayerSettingsSlice = createSlice({
  name: 'mapWidgetsLayerSettings',
  initialState,
  reducers: {
    setMapLayers(
      state,
      { payload: { mapWidgetId, layers } }: PayloadAction<{ mapWidgetId: MapWidgetId; layers: MapLayerSettings[] }>,
    ) {
      state[mapWidgetId].layers = layers;
    },
    changeMapLayerState(
      state,
      { payload: { mapWidgetId, layerId } }: PayloadAction<{ mapWidgetId: MapWidgetId; layerId: MapLayer }>,
    ) {
      const currentLayerIndex = state[mapWidgetId].layers.findIndex((layer) => layer.id === layerId);
      if (currentLayerIndex !== -1) {
        state[mapWidgetId].layers.splice(currentLayerIndex, 1);
        state[mapWidgetId].removedLayer = layerId;
      } else {
        state[mapWidgetId].layers.push({ id: layerId, ...DEFAULT_LAYER_SETTINGS[layerId] });
      }
    },
    clearRemovedLayer(state, { payload: { mapWidgetId } }: PayloadAction<{ mapWidgetId: MapWidgetId }>) {
      state[mapWidgetId].removedLayer = null;
    },
    changeMapLayerOpacity(
      state,
      {
        payload: { mapWidgetId, id, opacity },
      }: PayloadAction<{ mapWidgetId: MapWidgetId } & Omit<MapLayerSettings, 'colorByValue' | 'size'>>,
    ) {
      state[mapWidgetId].layers = changeSettingsValue<number>(state[mapWidgetId].layers, id, 'opacity', opacity);
    },
    changeMapLayerPointSize(
      state,
      {
        payload: { mapWidgetId, id, pointSize },
      }: PayloadAction<{ mapWidgetId: MapWidgetId; id: MapLayer; pointSize: number }>,
    ) {
      state[mapWidgetId].layers = changeSettingsValue<number>(state[mapWidgetId].layers, id, 'size', pointSize);
    },
    changeColorByValue(
      state,
      {
        payload: { mapWidgetId },
      }: PayloadAction<{ mapWidgetId: MapWidgetId; colorByDomainPayload: GetColorByDomain[] }>,
    ) {
      state[mapWidgetId].domainStatus = RequestStatus.LOADING;
      state[mapWidgetId].domainError = null;
    },
    changeColorByValueSuccess(
      state,
      {
        payload: { mapWidgetId, domains },
      }: PayloadAction<{
        mapWidgetId: MapWidgetId;
        domains: { id: MapLayer; field: Column; domain: string[] | number[] }[];
      }>,
    ) {
      domains.forEach(({ id, field, domain }) => {
        const currentOpacity = selectMapLayerOpacity({ mapWidgetsLayerSettings: state }, mapWidgetId, id) / 100;
        const colorList = getPreDefinedColorListByLayerAndField(id, field.name);
        const rangeColors = layersColoredByColorPalette.includes(id)
          ? generateRangeColorPalette(domain.length, colorList, currentOpacity)
          : field.type === PostgreDataType.VARCHAR
          ? generateRGBAColors(domain.length, currentOpacity, colorList)
          : generateRGBAColorPalette(currentOpacity, colorList);

        state[mapWidgetId].layers = changeSettingsValue<MapLayerSettings['colorByValue']>(
          state[mapWidgetId].layers,
          id,
          'colorByValue',
          {
            field: field.name,
            domain,
            range: rangeColors,
          },
        );
      });

      state[mapWidgetId].domainStatus = RequestStatus.SUCCESS;
    },
    changeColorByValueError(
      state,
      { payload: { mapWidgetId, error } }: PayloadAction<{ mapWidgetId: MapWidgetId; error: string }>,
    ) {
      state[mapWidgetId].domainStatus = RequestStatus.FAILURE;
      state[mapWidgetId].domainError = error;
    },
    clearColorByValue(
      state,
      { payload: { mapWidgetId, mapLayerId } }: PayloadAction<{ mapWidgetId: MapWidgetId; mapLayerId: MapLayer }>,
    ) {
      state[mapWidgetId].layers = changeSettingsValue<MapLayerSettings['colorByValue']>(
        state[mapWidgetId].layers,
        mapLayerId,
        'colorByValue',
        null,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = mapWidgetsLayerSettingsSlice;
