import { AppRoute } from 'constants/AppRoute';
import { TestLocators } from 'constants/Locators';

import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security } from '@okta/okta-react';
import { useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { DefaultLayout } from 'app/components/common/DefaultLayout';
import { MainLayout } from 'app/components/containers/layout/MainLayout';
import { DocumentationPage } from 'app/components/pages/DocumentationPage/Loadable';
import { EconomicsPage } from 'app/components/pages/EconomicsPage/Loadable';
import { ErrorPage } from 'app/components/pages/ErrorPage';
import { ForbiddenPage } from 'app/components/pages/ForbiddenPage';
import { ImmersePage } from 'app/components/pages/ImmersePage/Loadable';
import { LandingPage } from 'app/components/pages/LandingPage/Loadable';
import { ProductionPredictionPage } from 'app/components/pages/ProductionPredictionPage/Loadable';
import { UnauthorizedPage } from 'app/components/pages/UnauthorizedPage';
import { WellPlannerPage } from 'app/components/pages/WellPlannerPage/Loadable';
import { CommonRoute } from 'app/containers/CommonRoute';
import { ErrorHandler } from 'app/containers/ErrorHandler';
import { PrivateRoute, usePrivateRoute } from 'app/containers/PrivateRoute';
import { SocketHandler } from 'app/containers/SocketHandler';
import { trackUserInfo } from 'common/analytics';
import { history } from 'common/history';

import { OktaLoginPage } from './components/pages/OktaLoginPage/Loadable';

export function App() {
  useEffect(() => {
    trackUserInfo();
  }, []);
  usePrivateRoute();

  const OKTA_ISSUER = 'https://secure.signinpreview.spglobal.com/oauth2/spglobal';
  const OKTA_CLIENT_ID = '0oa22dlrf9ev4hqej0h8';
  const OKTA_SCOPES = 'openid email profile offline_access';

  const scopes = OKTA_SCOPES as string;
  const scopesArray = scopes && scopes.split(/\s+/);

  const oktaConfig = {
    issuer: OKTA_ISSUER as string,
    clientId: OKTA_CLIENT_ID as string,
    redirectUri: `${window.location.origin}`,
    scopes: scopesArray || [],
    pkce: true,
    disableHttpsCheck: true,
  };

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri?: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const oktaAuth = new OktaAuth(oktaConfig);

  return (
    <Router history={history}>
      <ErrorHandler>
        <SocketHandler>
          <Switch>
            <PrivateRoute
              exact
              path={AppRoute.ROOT}
              component={LandingPage}
              layout={MainLayout}
              testId={TestLocators.Impact_Landing_Page}
            />
            <PrivateRoute path={AppRoute.IMMERSE} component={ImmersePage} layout={DefaultLayout} />
            <PrivateRoute
              path={AppRoute.PRODUCTION_PREDICTION}
              component={ProductionPredictionPage}
              layout={MainLayout}
            />
            <PrivateRoute path={AppRoute.WELL_PLANNER} component={WellPlannerPage} layout={MainLayout} />
            <PrivateRoute path={AppRoute.ECONOMICS} component={EconomicsPage} layout={MainLayout} />
            <PrivateRoute path={AppRoute.DOCUMENTATION} component={DocumentationPage} layout={MainLayout} />
            <CommonRoute path={AppRoute.FORBIDDEN} component={ForbiddenPage} layout={DefaultLayout} />
            <CommonRoute path={AppRoute.UNAUTHORIZED} component={UnauthorizedPage} layout={DefaultLayout} />
            <CommonRoute path={AppRoute.ERROR} component={ErrorPage} layout={DefaultLayout} />

            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
              <Switch>
                <SecureRoute exact path={AppRoute.OKTA_LOGIN} component={() => <></>} />
                <Route path={AppRoute.OKTA_LOGIN_PAGE} component={OktaLoginPage} />
              </Switch>
            </Security>

            <Redirect to={AppRoute.ROOT} />
          </Switch>
        </SocketHandler>
      </ErrorHandler>
    </Router>
  );
}
