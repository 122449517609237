import { ComponentType } from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

export interface CommonRouteProps extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  layout?: ComponentType<any>;
}

export function CommonRoute({ component: Component, layout: Layout, ...rest }: CommonRouteProps) {
  return (
    <Route
      {...rest}
      render={(props) =>
        Layout ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}
