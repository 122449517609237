import { AppRoute, createImmerseBaseUrl } from 'constants/AppRoute';
import { TestLocators } from 'constants/Locators';

import { Divider } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

// eslint-disable-next-line no-restricted-imports
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import {
  selectIsJupyterEnabled,
  selectIsPlanPredictEnabled,
  selectIsSqlEditorEnabled,
  userSelectedDatabase,
} from 'app/containers/PrivateRoute/selectors';
import { actions } from 'app/containers/PrivateRoute/slice';
import { headerNavClick as trackEvent } from 'common/analytics';
import { translations } from 'locales/i18n';
import { log } from 'services/log';
import { LogEvent } from 'services/LogEvent';
import { LogSource } from 'services/LogSource';

import { DownloadShapefile } from './DownloadShapefile';
import { HeaderItem } from './HeaderItem';
import { Help } from './Help';
import { Jupyter } from './Jupyter';
import { Logo } from './Logo';
import Notifications from './Notifications';
import { PriceFeed } from './PriceFeed';
import { Profile } from './Profile';
import { useStyles } from './styles';

export interface HeaderProps {
  width: Breakpoint;
}

function getActiveMenuItem(pathname: string) {
  if (pathname.includes(AppRoute.PRODUCTION_PREDICTION)) {
    return AppRoute.PRODUCTION_PREDICTION;
  } else if (pathname.includes(AppRoute.WELL_PLANNER)) {
    return AppRoute.WELL_PLANNER;
  } else if (pathname.includes(AppRoute.ECONOMICS)) {
    return AppRoute.ECONOMICS;
  }

  return null;
}

function HeaderComponent({ width }: HeaderProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const [anchorHelpEl, setAnchorHelpEl] = useState<HTMLDivElement | null>(null);
  const [shapefileAnchorEl, setShapefileAnchorEl] = useState<HTMLDivElement | null>(null);
  const isWidthDownMd = isWidthDown('md', width);
  const isSqlEditorEnabled = useSelector(selectIsSqlEditorEnabled);
  const isJupyterEnabled = useSelector(selectIsJupyterEnabled);
  const isPlanPredictEnabled = useSelector(selectIsPlanPredictEnabled);
  const activeMenuItem = useMemo(() => getActiveMenuItem(pathname), [pathname]);
  const selectedDatabaseFromRedux = useSelector(userSelectedDatabase);

  const handleMenuItemClick = useCallback(
    ({ currentTarget: { id } }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if ([AppRoute.WELL_PLANNER, AppRoute.PRODUCTION_PREDICTION, AppRoute.ECONOMICS].includes(id)) {
        let source = LogSource.UNKNOWN;
        switch (id) {
          case AppRoute.PRODUCTION_PREDICTION:
            source = LogSource.PREDICT_PAGE_TAB;
            break;

          case AppRoute.WELL_PLANNER:
            source = LogSource.PLAN_PAGE_TAB;
            break;

          case AppRoute.ECONOMICS:
            source = LogSource.ECONOMICS_PAGE_TAB;
            break;

          default:
            break;
        }
        log.info({
          event: LogEvent.CLICK,
          source,
        });
      }

      history.push(id);
      trackEvent(id);
    },
    [history],
  );

  const handleHelpClick = useCallback(({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorHelpEl(currentTarget);
  }, []);

  const handleHelpClose = useCallback(() => {
    setAnchorHelpEl(null);
  }, []);

  const handleShapefileClick = useCallback(({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShapefileAnchorEl(currentTarget);
  }, []);

  const handleShapefileClose = useCallback(() => {
    setShapefileAnchorEl(null);
  }, []);

  useEffect(() => {
    actions.setIsUserFromIframe(window.self !== window.top);
  }, []);

  return (
    <header className={classes.header}>
      <div className={classes.nav}>
        <Logo />
        <HeaderItem
          onClick={handleMenuItemClick}
          id={`${createImmerseBaseUrl(selectedDatabaseFromRedux)}${AppRoute.DASHBOARDS}`}
          label={t(translations.DASHBOARDS.TITLE)}
          testId={TestLocators.Header_Dashboards}
        />
        <HeaderItem
          onClick={handleMenuItemClick}
          id={`${createImmerseBaseUrl(selectedDatabaseFromRedux)}${AppRoute.DATA_MANAGER}`}
          label={t(translations.DATA_MANAGER.TITLE)}
          testId={TestLocators.Header_DataManager}
        />
        <HeaderItem
          onClick={handleMenuItemClick}
          disabled={!isSqlEditorEnabled}
          id={`${createImmerseBaseUrl(selectedDatabaseFromRedux)}${AppRoute.SQL_EDITOR}`}
          label={t(translations.SQL_EDITOR.TITLE)}
          testId={TestLocators.Header_GreyedOut_SQLEditor}
        />
        <HeaderItem
          onClick={handleHelpClick}
          showDropDownIcon
          showBorder={Boolean(anchorHelpEl)}
          label={t(translations.HELP.TITLE)}
          testId={TestLocators.Header_Help}
        />
        <Help anchorEl={anchorHelpEl} onClose={handleHelpClose} />
        <HeaderItem
          onClick={handleShapefileClick}
          showDropDownIcon
          showBorder={Boolean(shapefileAnchorEl)}
          label={t(translations.DOWNLOAD_SHAPEFILE.TITLE)}
          testId={TestLocators.Header_Downloads}
        />
        <DownloadShapefile anchorEl={shapefileAnchorEl} onClose={handleShapefileClose} />
      </div>
      <div className={classes.nav}>
        {isWidthDownMd && <Divider orientation="vertical" className={classes.divider} />}
        <HeaderItem
          onClick={handleMenuItemClick}
          disabled={!isPlanPredictEnabled}
          id={AppRoute.PRODUCTION_PREDICTION}
          testId={isPlanPredictEnabled ? TestLocators.Header_Predict_Enabled : TestLocators.Header_Predict_Disabled}
          showBorder={activeMenuItem === AppRoute.PRODUCTION_PREDICTION}
          label={t(translations.PRODUCTION_PREDICTION.TITLE)}
        />
        <HeaderItem
          onClick={handleMenuItemClick}
          disabled={!isPlanPredictEnabled}
          id={AppRoute.WELL_PLANNER}
          testId={isPlanPredictEnabled ? TestLocators.Header_Plan_Enabled : TestLocators.Header_Plan_Disabled}
          showBorder={activeMenuItem === AppRoute.WELL_PLANNER}
          label={t(translations.WELL_PLANNER.TITLE)}
        />
        <HeaderItem
          onClick={handleMenuItemClick}
          disabled={!isPlanPredictEnabled}
          id={AppRoute.ECONOMICS}
          testId={isPlanPredictEnabled ? TestLocators.Header_Economics_Enabled : TestLocators.Header_Economics_Disabled}
          showBorder={activeMenuItem === AppRoute.ECONOMICS}
          label={t(translations.ECONOMICS.TITLE)}
        />
        <Divider orientation="vertical" className={classes.divider} />
        <PriceFeed />
        {isJupyterEnabled && <Jupyter />}
        <Notifications />
        <Profile />
      </div>
    </header>
  );
}

export const Header = withWidth()(HeaderComponent);
