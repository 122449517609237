import { BASE_URL_DATA } from 'constants/API';
import { AppRoute } from 'constants/AppRoute';

import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Socket, io } from 'socket.io-client';

import {
  selectIsAuthenticated,
  selectIsAuthorizedSuccessfully,
  selectIsForbidden,
} from 'app/containers/PrivateRoute/selectors';
import { actions, reducer, sliceKey } from 'app/containers/SocketHandler/slice';
import { PriceFeedData } from 'app/containers/SocketHandler/types';
import { IMMERSE_SUBJECT_TYPE, SUBJECT_TYPE, immerseNotifier } from 'services/immerseNotifier';
import { useInjectReducer } from 'utils/redux-injectors';

const SOCKET_SERVER_URL = BASE_URL_DATA.base_url;

interface SocketHandlerProps {
  children: ReactElement;
}

export let socket: Socket | undefined;

export function SocketHandler({ children }: SocketHandlerProps) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAuthorizedSuccessfully = useSelector(selectIsAuthorizedSuccessfully);
  const isForbidden = useSelector(selectIsForbidden);

  const receiveSocketMessageHandler = useCallback(
    ({ data: eventData, source }: MessageEvent) => {
      if (typeof eventData !== 'object') {
        return;
      }

      const messageSubject = eventData.source === 'immerse' ? eventData.subject : null;
      if (messageSubject === IMMERSE_SUBJECT_TYPE.GET_INIT_INFO && socket) {
        socket.emit('live_price:init', null, (data: PriceFeedData) => {
          immerseNotifier.send(source as Window, SUBJECT_TYPE.PRICE_FEED, data);
          dispatch(actions.setPriceFeed(data));
        });

        // subscription on live price update action
        socket.on('live_price:fetch', (data: PriceFeedData) => {
          immerseNotifier.send(source as Window, SUBJECT_TYPE.PRICE_FEED, data);
          dispatch(actions.setPriceFeed(data));
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', receiveSocketMessageHandler);

    return () => {
      window.removeEventListener('message', receiveSocketMessageHandler);
    };
  }, [receiveSocketMessageHandler]);

  useEffect(() => {
    if (isAuthorizedSuccessfully && isAuthenticated && !isForbidden) {
      socket = io(SOCKET_SERVER_URL as string, {
        withCredentials: true,
      });

      socket.on('connect_error', (error: any) => {
        if (error?.description === 403) {
          socket?.disconnect();
          history.push(AppRoute.UNAUTHORIZED);
        }
      });
    }

    return () => {
      socket?.disconnect();
    };
  }, [isAuthenticated, isAuthorizedSuccessfully, isForbidden, history, dispatch]);

  return children;
}
