import { RequestStatus } from 'constants/RequestStatus';

import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { WellInfoData, WellInfoWidgetState } from './types';

export const initialState: WellInfoWidgetState = {
  status: RequestStatus.IDLE,
  error: null,
  data: null,
};

export const {
  actions,
  reducer,
  name: sliceKey,
} = createSlice({
  name: 'wellInfoWidget',
  initialState,
  reducers: {
    loadWellInfo(state, _action: PayloadAction<{ uwi: string }>) {
      state.status = RequestStatus.LOADING;
      state.error = null;
    },
    wellInfoLoaded(state, action: PayloadAction<WellInfoData>) {
      state.status = RequestStatus.SUCCESS;
      state.data = action.payload;
    },
    resetWellInfo() {
      return { ...initialState };
    },
    wellInfoError(state, action: PayloadAction<string>) {
      state.status = RequestStatus.FAILURE;
      state.error = action.payload;
      state.data = null;
    },
    clearWellInfoError(state) {
      state.error = null;
      state.status = RequestStatus.IDLE;
    },
  },
});
