import { MapLayer } from 'app/components/containers/map/MapLayersSelect/types';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';

export const omniSciAllowedFiltersColumnNames = [ImpactWellAndProdColumn.COUNTRY];

export const VEGA_LAYERS_ORDER: MapLayer[] = [
  MapLayer.ISOPACH,
  MapLayer.ACREAGE,
  MapLayer.MIDSTREAM,
  MapLayer.WELL_PATH,
  MapLayer.WELLS,
];

export const ACREAGE_LAYER_COLOR_LIST = [
  'rgb(172, 229, 199)', // 0
  'rgb(234, 85, 69)', // 1
  'rgb(239, 155, 32)', // 2
  'rgb(237, 225, 91)', // 3
  'rgb(135, 188, 69)', // 4
  'rgb(39, 174, 239)', // 5
  'rgb(131, 109, 197)', // 6
];

export const MIDSTREAM_CATEGORY_LAYER_COLOR_LIST = [
  'rgb(234, 85, 69)', // Gas,
  'rgb(135, 188, 69)', // Oil
  'rgb(172, 229, 199)', // Product
];

export const ISOPACH_CATEGORY_LAYER_COLOR_LIST = [
  'rgb(255, 0, 0)',
  'rgb(255, 64, 0)',
  'rgb(255, 128, 0)',
  'rgb(255, 192, 0)',
  'rgb(255, 255, 0)',
  'rgb(128, 255, 0)',
  'rgb(0, 255, 0)',
  'rgb(0, 128, 128)',
  'rgb(0, 0, 255)',
];

export const THERMAL_MATURITY_LAYER_COLOR_LIST = [
  'rgb(239, 155, 32)', // Wet Gas
  'rgb(234, 85, 69)', // Dry Gas
  'rgb(135, 188, 69)', // Oil
];

export const STRUCTURE_LAYER_COLOR_LIST = [
  'rgb(255, 0, 0)',
  'rgb(255, 64, 0)',
  'rgb(255, 128, 0)',
  'rgb(255, 192, 0)',
  'rgb(255, 255, 0)',
  'rgb(128, 255, 0)',
  'rgb(0, 255, 0)',
  'rgb(0, 128, 128)',
  'rgb(0, 0, 255)',
];

export const FAULTS_LAYER_COLOR_LIST = ['rgb(150, 75, 0)'];
