import {
  DEFAULT_ACREAGE_LAYER_OPACITY,
  LINE_SIZE_DEFAULT,
  POINT_SIZE_DEFAULT,
} from 'app/components/containers/map/MapLayersSelect/constants';
import {
  ACREAGE_COLOR_BY_COLUMNS,
  COLOR_BY_COLUMNS,
  COMPANY_ACREAGE_COLOR_BY_COLUMNS,
  ISOPACH_COLOR_BY_COLUMNS,
  MIDSTREAM_COLOR_BY_COLUMNS,
  SUBSURFACE_FAULTS_COLOR_BY_COLUMNS,
  SUBSURFACE_STRUCTURE_COLOR_BY_COLUMNS,
  THERMAL_MATURITY_COLOR_BY_COLUMNS,
  WELL_PATH_COLOR_BY_COLUMNS,
} from 'app/components/containers/map/MapLayersSelect/MapLayerSelectItem/MapLayerSettings/MapLayerColorBy/constants';
import { AcreageAndInventoryColumn } from 'types/AcreageAndInventoryColumn';
import { CompanyAcreageColumn } from 'types/CompanyAcreageColumn';
import { FaultsColumn } from 'types/FaultsColumns';
import { ImpactWellAndProdColumn } from 'types/ImpactWellAndProdColumn';
import { IsopachColumn } from 'types/IsopachColumn';
import { MidstreamPipelinesColumn } from 'types/MidstreamPipelinesColumn';
import { StructureColumn } from 'types/StructureColumn';
import { ThermalMaturityColumn } from 'types/ThermalMaturityColumn';
import { WellPathColumn } from 'types/WellPathColumn';
import { MapWidgetId } from 'types/Widget';

import { FiledTypeSettings, MapLayer, MapLayerConfig, MapLayerGroup, MapLayerSettings } from './types';

export const mapLayersConfig: Record<MapWidgetId, MapLayerConfig[]> = {
  [MapWidgetId.PREDICT_MAP_WIDGET]: [
    {
      groupId: MapLayerGroup.WELL_AND_PRODUCTION,
      layerIds: [MapLayer.WELLS, MapLayer.WELL_PATH, MapLayer.ACREAGE, MapLayer.MIDSTREAM],
    },
  ],
  [MapWidgetId.PLAN_MAP_WIDGET]: [
    {
      groupId: MapLayerGroup.WELL_AND_PRODUCTION,
      layerIds: [
        MapLayer.WELLS,
        MapLayer.WELL_PATH,
        MapLayer.PREDICTION,
        MapLayer.ACREAGE,
        MapLayer.MIDSTREAM,
        MapLayer.ISOPACH,
        MapLayer.THERMAL_MATURITY,
        MapLayer.SUBSURFACE_STRUCTURE,
        MapLayer.SUBSURFACE_FAULTS,
        MapLayer.COMPANY_ACREAGE,
      ],
    },
  ],
};

export const ColorByOptionsByLayerId: Record<MapLayer, FiledTypeSettings> = {
  [MapLayer.WELLS]: COLOR_BY_COLUMNS,
  [MapLayer.WELL_PATH]: WELL_PATH_COLOR_BY_COLUMNS,
  [MapLayer.PREDICTION]: COLOR_BY_COLUMNS,
  [MapLayer.ACREAGE]: ACREAGE_COLOR_BY_COLUMNS,
  [MapLayer.MIDSTREAM]: MIDSTREAM_COLOR_BY_COLUMNS,
  [MapLayer.ISOPACH]: ISOPACH_COLOR_BY_COLUMNS,
  [MapLayer.THERMAL_MATURITY]: THERMAL_MATURITY_COLOR_BY_COLUMNS,
  [MapLayer.SUBSURFACE_STRUCTURE]: SUBSURFACE_STRUCTURE_COLOR_BY_COLUMNS,
  [MapLayer.SUBSURFACE_FAULTS]: SUBSURFACE_FAULTS_COLOR_BY_COLUMNS,
  [MapLayer.COMPANY_ACREAGE]: COMPANY_ACREAGE_COLOR_BY_COLUMNS,
};

export const DEFAULT_LAYER_SETTINGS: Record<MapLayer, Omit<MapLayerSettings, 'id'>> = {
  [MapLayer.WELLS]: {
    opacity: 100,
    colorByValue: { field: ImpactWellAndProdColumn.OPERATOR, domain: null, range: null },
    size: POINT_SIZE_DEFAULT,
  },
  [MapLayer.WELL_PATH]: {
    opacity: 100,
    colorByValue: { field: WellPathColumn.OPERATOR, domain: null, range: null },
    size: LINE_SIZE_DEFAULT,
  },
  [MapLayer.PREDICTION]: {
    opacity: 100,
    colorByValue: null,
    size: 0,
  },
  [MapLayer.ACREAGE]: {
    opacity: DEFAULT_ACREAGE_LAYER_OPACITY,
    colorByValue: { field: AcreageAndInventoryColumn.ACREAGE_EST_CLASS, domain: null, range: null },
    size: 0,
  },
  [MapLayer.MIDSTREAM]: {
    opacity: 100,
    colorByValue: { field: MidstreamPipelinesColumn.CATEGORY, domain: null, range: null },
    size: 1,
  },
  [MapLayer.ISOPACH]: {
    opacity: 60,
    colorByValue: { field: IsopachColumn.DEPTH_FT, domain: null, range: null },
    size: 1,
  },
  [MapLayer.THERMAL_MATURITY]: {
    opacity: 60,
    colorByValue: { field: ThermalMaturityColumn.PLAY_ZONE_TYPE, domain: null, range: null },
    size: 1,
  },
  [MapLayer.SUBSURFACE_STRUCTURE]: {
    opacity: 60,
    colorByValue: { field: StructureColumn.DEPTH_FT, domain: null, range: null },
    size: 1,
  },
  [MapLayer.SUBSURFACE_FAULTS]: {
    opacity: 60,
    colorByValue: { field: FaultsColumn.FORMATION, domain: null, range: null },
    size: 1,
  },
  [MapLayer.COMPANY_ACREAGE]: {
    opacity: 60,
    colorByValue: { field: CompanyAcreageColumn.OPERATOR, domain: null, range: null },
    size: 1,
  },
};
