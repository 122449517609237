import { isAxiosError } from 'utils/axios';

export const getErrorMessage = (error: unknown) => {
  let errorMessage = 'Unknown error';
  if (isAxiosError(error)) {
    errorMessage =
      (typeof error.response?.data === 'string' ? error.response?.data : error.response?.data.message) ?? error.message;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  return errorMessage;
};

export const getErrorData = (error: unknown): object | undefined => {
  return isAxiosError(error) ? error.response?.data.data : undefined;
};
